@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: center;
	align-items: center;
	margin-bottom: @panel-body-padding;
	padding: 15px;
	text-align: center;
	background-color: #fff;
	line-height: @line-height-computed;
	border-radius: @border-radius-large;
	border: 1px solid @panel-inner-border;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	margin-bottom: 5px;
}

.header-text {
	padding-left: 5px;
	margin: 0;
}

.icon {
	fill: @gray-light;
}

.description {
	color: @text-muted;
	margin: 0;
}
