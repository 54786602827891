@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/mixins/box-shadow.less';

@payment-method-gutter: 16px;
@payment-method-selector-height: 140px;
@payment-method-transition-props: background-color @btn-transition-duration ease-in-out, color 0.2s ease-in-out,
	border-color 0.2s ease-in-out;

.label {
	display: block;
	margin-bottom: @payment-method-gutter;
}

.selector {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: @payment-method-selector-height;
	padding: 15px;
	padding-left: 23px;
	background-color: #fff;
	color: @text-muted;
	line-height: @line-height-computed;
	.box-shadow-small;
	border-radius: @border-radius-large;
	border: 1px solid @gray-light;
	cursor: pointer;
	transition: @payment-method-transition-props;

	&.is-active {
		color: @text-color;

		.expiry-date {
			color: @text-muted;
		}
	}

	&.failed {
		border-color: @color-red-validation;
	}

	.description {
		font-weight: 500;
		font-size: @font-size-base-plus;
		width: 100%;
		margin-bottom: 9px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.card-number {
		font-size: @font-size-large;
		margin-bottom: 16px;
		font-weight: 400;
	}

	.expiry-date {
		font-size: @font-size-base-minus;
	}

	.brand-icon {
		text-align: right;
		display: block;
	}

	.selected-icon,
	.unselected-icon {
		text-align: right;
	}
}

.selected-icon {
	background-color: @color-complementary-green;
	color: white;
	border-radius: 100%;
	transition: @payment-method-transition-props;
}

.unselected-icon {
	color: @gray-light;
	border: 1px solid @gray-light;
	border-radius: 100%;
	transition: @payment-method-transition-props;
}

.card-error {
	color: @color-red-validation;
}

.row {
	display: flex;
	flex-direction: row;
	flex: 1;
	width: 100%;
}

.column-left {
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: 80%;
	overflow: hidden;
}

.column-right {
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: 20%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-direction: column;
}

.column-right-top {
	flex: 1;
}

.column-right-bottom {
	flex: 0 0 30px;
	align-self: flex-end;
	align-content: end;
}

.method {
	overflow: hidden;
	padding-top: 4px;
}

.radio {
	position: absolute;
	left: -9000px;

	&:focus + .label {
		outline: 1px dotted black;
		outline: -webkit-focus-ring-color auto 5px;
		z-index: 4;
	}
}

.new-payment-method {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: center;
	align-items: center;
	margin-bottom: @payment-method-gutter;
	height: @payment-method-selector-height;
	padding: 0 @payment-method-gutter;
	text-align: center;
	color: @text-muted;
	border: 1px dashed @gray-mid;
	box-shadow: none;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: @text-muted;
		text-decoration: none;
	}

	@media @small {
		height: auto;
		padding: 10px;
		flex-direction: row;
	}

	.heading {
		margin: 0;
		font-size: @font-size-base-plus;
		font-weight: 400;
		// fix IE10/11 flex bug
		max-width: 100%;

		@media @small {
			display: inline;
		}
	}

	.text {
		font-size: @font-size-small;
		margin: 0;
		line-height: 20px;
		// fix IE10/11 flex bug
		max-width: 100%;

		@media @small {
			display: none;
		}
	}

	.add-icon {
		color: @gray-light;
		margin-bottom: 4px;

		@media @small {
			display: inline-block;
			vertical-align: bottom;
			margin-right: 10px;
		}
	}
}
