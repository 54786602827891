@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.recurring-gift-line {
	font-size: @font-size-base-minus;
}

.currency-code {
	font-weight: 500;
}

.amount {
	font-weight: 700;
}
