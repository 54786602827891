@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@button-height: 45px;
@button-border-width: 1px;
@button-padding-vertical: @spacing-base*2;
@button-padding-horizontal: @grid-gutter-width;
@button-line-height: @button-height - (@button-padding-vertical*2) - (@button-border-width*2);
@spinner-size: 32px;

.loading-spinner {
	display: block;
	background: none;
	width: @spinner-size;
	height: @spinner-size;
	border-radius: 50%;
	border: 3px solid rgba(255, 255, 255, 0.5);
	border-top-color: white;
	animation: btn-spinner-rotating 1s linear infinite;
	position: absolute;
	left: calc(~'50% - (@{spinner-size} / 2)');
	top: calc(~'50% - (@{spinner-size} / 2)');
}

@keyframes btn-spinner-rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

&.hidden-text {
	visibility: hidden;
}

.button {
	position: relative;
	display: inline-block;
	text-align: center;
	padding: @button-padding-vertical @button-padding-horizontal;
	width: 100%;
	border: none;
	border-radius: @button-height;
	min-height: @button-height;
	line-height: @button-line-height;
	font-size: @font-size-base-plus;
	font-weight: 700;
	transition: @transition-duration-short;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}

	&.ghost {
		border-style: solid;
		border-width: @button-border-width;
		background-color: @btn-default-color;
	}

	&.text {
		border-style:none;
		background-color: initial;
		padding: 0;
	}

	&[disabled] {
		cursor: not-allowed;
	}
}

.blocked {
	color: white;
	background-color: @gray-light;
}
