@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.need-help {
	position: absolute;
	top: 0;
	right: floor((@grid-gutter-width / 2));

	@media @small {
		position: static;
		margin-top: @spacing-base;
	}
}

.mobile-number-field {
	position: relative;
}
