@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@button-height: 40px;

.container {
	position: relative;
	flex: none;
	flex-direction: column;
	display: flex;
	height: @main-header-height;
	justify-content: center;
	margin: 0 10px;

	@media @small {
		position: inherit;
		height: @main-header-height-small;
		margin: 0;
		font-size: 13px;
		width: 90px;
	}

	@media screen and (max-width: 340px) {
		font-size: 12px;
	}
}

.button {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	color: @navbar-default-color;
	text-decoration: none;
	border-radius: @button-height;
	height: @button-height;
	padding: @spacing-base*2 @grid-gutter-width;
	background-color: transparent;
	border: 1px transparent solid;
	border-radius: @button-height;
	transition: border-color 0.2s ease-in-out;

	@media @small {
		flex-direction: column;
	}

	&:hover,
	&:focus {
		color: @navbar-default-color;
		border-color: rgba(255, 255, 255, 0.8);
	}

	&:disabled {
		opacity: 0.5;
	}
}
