@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.download-icon {
	width: 60px;
	height: 60px;
	margin: auto;
	border-radius: 50%;
	background-color: @gray-lightest;
	color: @gray-mid;
}

.download-description {
	padding: 12px;
	text-align: center;
}
