@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.locked-fund-name {
	font-size: 17px;
}

.line-items {
	padding: 16px 0;
}

.line-items-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0 16px;

	span {
		margin-right: auto;
	}

	@media (max-width: 375px) {
		flex-direction: column;
		gap: 10px;

		> div {
			width: 100%;
			text-align: center;
		}
	}
}

.line-items-footer-space-between {
	justify-content: space-between;
}

.line-item {
	display: flex;
	padding: 16px;
	background-color: #F5F6FA;
	border-radius: 3px;
	flex-direction: column;
	margin-bottom: 16px;
}

.line-item-remove {
	display: inline-block;
	height: 28px;
}

.line-item-remove-btn {
	min-height: 28px;
	height: 28px;
	font-size: 14px;
	width: auto;

	&>span {
		padding: 0px 12px;
	}

	.line-item-remove-btn-icon {
		position: relative;
		top: 1px;
		margin-right: 4px;
		height: 12px;
		width: 12px;
	}
}

.line-item-add-btn {
	height: 40px;
	max-width: 200px;

	.line-item-add-btn-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.line-items-total {
	font-size: 16px;

	.line-items-total-amount {
		font-weight: 700;
	}
}

.line-item-input-container {
	display: flex;
	align-items: center;
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	background-color: @input-bg;
	width: 100%;
	height: @input-height-base;
	font-size: @font-size-base;

	&:focus-within {
		border-color: @input-border-focus;
	}
}

.line-item-input {
	border: none;
	outline: none;
	width: 100%;
	caret-color: @theme_colors_common-black;

	&:focus {
		outline: none;
	}

}

.currency-symbol {
	padding-left: 15px;
}

.input-placeholder {
	color: @input-color-placeholder;
}

.input-text {
	color: @input-color;
}

.form-control-fund {
	margin-bottom: 12px;
}

.form-control-amount {
	margin-bottom: 8px;
}
