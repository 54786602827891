//
// Typography
// --------------------------------------------------

// Headings
// -------------------------
h1, .h1 {
	font-size: @font-size-h1;
	line-height: 39px;
	font-weight: 300;
}

h2, .h2 {
	font-size: 26px;
	line-height: 32px;
	font-weight: 300;
}

h3, .h3 {
	font-size: 20px;
	line-height: @line-height-h3;
	font-weight: 300;
	margin-bottom: 20px;
	margin-top: 30px;
}

h4, .h4 {
	font-size: @font-size-h4;
	line-height: 19px;
	font-weight: 500;
}

h6 {
	font-weight: 400;
}

// Desktop
@media (min-width: @screen-md-min) {

	h2, .h2 {
		font-size: @font-size-h2;
		line-height: 29px;
		font-weight: 300;
	}

	h3, .h3 {
		font-size: @font-size-h3;
		line-height: 25px;
		font-weight: 400;
	}

	h4, .h4 {
		font-size: @font-size-h4;
		line-height: 19px;
		font-weight: 500;
	}
}

// Body text
// -------------------------

.lead {
	font-size: @font-size-large;
	line-height: @line-height-base;
	margin: 30px 0;
	font-weight: normal;

	h3& {

		// desktop
		@media (min-width: @screen-md-min) {
			font-size: @font-size-h3;
			line-height: 32px;
			margin-bottom: 40px;
			margin-top: 0;
		}

		@media @mobile{
			font-size: (@font-size-h3+2);
			padding-top: 28px;
			margin-bottom: 30px;
			margin-top: 0
		}
	}
}

// description lists
// -------------------------
.dl-horizontal {

	dt {
		text-align: left;
	}
}

// Padding set to match labels and inputs when in a form
dl.form-group {
	dt, dd {
		padding-top: (@padding-large-vertical + 1);

		@media (max-width: @screen-xs-max) {
			padding-top: (@padding-small-vertical + 1);
		}
	}

	dd {
		@media (max-width: @screen-sm-max) {
			padding-top: 0;
		}
	}

	dt {
		font-size: @font-size-base-minus;
	}
}
