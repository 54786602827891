@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@tab-icon-height: 30px;
@tab-transition-props: @transition-duration ease-in;

.tabs {
	display: flex;
	padding: 0;
	margin: 0;
}

.tab {
	flex: auto;
	list-style: none;
	font-weight: 500;
}

.active {
	.button {
		color: @text-color;
	}
}

.button {
	width: 100%;
	background: none;
	border-style: none none solid;
	border-width: 0 0 5px;
	border-color: transparent;
	border-radius: 0;
	color: @text-muted;
	padding-bottom: 10px;
	line-height: @tab-icon-height;
	transition: border-color @tab-transition-props, color @tab-transition-props;

	&:hover,
	&:active,
	&:focus {
		color: @text-color;
		outline: none;

		.label {
			text-decoration: underline;
		}
	}
}

.icon,
.label {
	display: inline-block;
	vertical-align: middle;
}

.icon {
	margin-right: 10px;
}
