@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.footer {
	text-align: center;
	border-top: 2px solid @gray-lightest;
	margin-top: @spacing-vertical-small;
	padding-top: @spacing-vertical;
	padding-bottom: @spacing-vertical-small;
}

.button-container {
	float: none;
	padding: 0;
	display: inline-block;
	text-align: center;
	margin: @spacing-base 0 @spacing-vertical;
	@media @small {
		display: block;
	}
}
