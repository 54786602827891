@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	text-align: center;
	display: block;
	position: relative;
	padding: 58px 0 30px 0;
	color: @text-color;
	z-index: 2;
}

.name {
	font-size: 18px;
	margin: 0 auto;
	max-width: 960px;
	padding: 0 16px;
}

.title {
	display: inline-block;
	font-size: 32px;
	max-width: 960px;
	padding: 10px 16px 0 16px;
	margin: 0 auto;
}
