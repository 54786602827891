@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	display: flex;

	& :nth-child(1) {
		flex: none;
	}

	& :nth-child(2) {
		flex: auto;
	}
}
