@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	position: relative;
	flex: none;
	flex-direction: column;
	display: flex;
	height: @main-header-height;
	justify-content: center;
	padding-bottom: 5px;
	margin: 0 5px;

	@media @small {
		position: inherit;
		height: @main-header-height-small;
		width: @main-header-account-button-width-small;
		padding: 0 10px;
	}
}
