// Styles for graceful failure when the WebGiving React application fails to initialize

.graceful {
	@button-width: 134px;
	@button-height: 45px;
	@button-border-width: 1px;
	@button-padding-vertical: @spacing-base*2;
	@button-padding-horizontal: @grid-gutter-width;
	@button-line-height: @button-height - (@button-padding-vertical*2) - (@button-border-width*2);
	@footer-height: 150px;
	@footer-height-small: 205px;

	opacity: 0;
	transition: opacity 300ms ease-in-out 0s;

	&-header {
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: @zindex-navbar-fixed;
		color: @navbar-default-color;

		&-container {
			height: @main-header-height;

			@media @small {
				padding: 0;
				width: 100%;
				height: @main-header-height-small;
			}
		}
	}

	&-logo {
		width: @main-header-height;
		height: @main-header-height;
		padding: 10px;

		@media @small {
			width: @main-header-height-small;
			height: @main-header-height-small;
			padding: 6px;
		}

		&-link {
			text-decoration: none;
		}
	}

	&-merchant-name {
		font-size: @font-size-base-plus;
		font-weight: 700;
		letter-spacing: 0.03em;
		margin-left: 7.5px;

		@media @small {
			font-size: @font-size-small;
			margin-left: 4px;
		}
	}

	&-main {
		display: block;
		height: 100vh;
		background-color: @main-background;

		&-container {
			max-width: @main-container-max-width;

			@media @small {
				padding: 0;
				max-width: none;
			}
		}

		&-panel-body {
			text-align: center;
			min-height: 280px;
			height: ~'calc(100vh - @{footer-height})';
			margin-top: 44px + @main-header-height;

			@media @small {
				height: ~'calc(100vh - @{footer-height-small})';
				margin-top: 44px + @main-header-height-small;
			}

			@media not @small {
				max-height: 600px;
			}
		}
	}

	&-heading {
		font-weight: 600;
		line-height: 1.5;
	}

	&-message {
		font-size: @font-size-base-plus;
		line-height: 1.5;
	}

	&-reload {
		margin-top: 16px;
		text-align: center;
		padding: @button-padding-vertical @button-padding-horizontal;
		line-height: @button-line-height;
		width: @button-width;
		border: none;
		height: @button-height;
		border-radius: @button-height;
		color: white;
		font-weight: 600;
	}

	&-footer {
		font-size: 12px;

		&-container {
			max-width: @main-container-max-width;
		}

		&-horiz-separator {
			margin-top: 35px;
		}

		&-content {
			display: flex;
			justify-content: space-between;
			max-width: @main-container-max-width;

			@media @small {
				flex-direction: column;
				align-items: center;
			}
		}

		&-info {
			@media @small {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&-pushpay-logo {
		width: 110px;
		height: 31px;
		color: @gray-light;
	}

	&-help-center {
		color: @gray-darker;

		@media @small {
			margin-top: 20px;
		}

		&:hover {
			color: @gray-darker;
		}
	}

	&-separator {
		color: @gray-light;
		padding: 0 10px;
	}

	&-copyright {
		@media @small {
			margin-top: 20px;
		}
	}
}
