@import '../../../../../Content/WebGiving/webgiving_theme/variables.less';

@ach-image-transition-props: @transition-duration ease-in-out;

.amount {
	color: @gray-dark;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
	font-weight: normal;
	text-align: center;
	font-size: @font-size-base;
	padding: 26px 0;

	@media @small {
		flex-wrap: wrap;
		font-size: @font-size-base-minus;
	}
}

.major {
	font-size: 60px;
	font-weight: 200;
	line-height: 0.8;

	@media @small {
		font-size: 50px;
	}
}

.currency-symbol {
	font-size: 24px;
	font-weight: 500;
	line-height: 1.1;
	margin-right: 5px;

	@media @small {
		font-size: 24px;
		font-weight: 400;
	}
}

.title {
	font-size: 24px;
	line-height: 24px;
	font-weight: 400;
	text-align: center;
}

.fees {
	text-align: center;
    display: flex;
    justify-content: center;
	padding-bottom: 16px;
}

.description {
	text-align: center;
    display: flex;
	flex-direction: column;
	align-items: center;
}
