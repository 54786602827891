@import "../bootstrap-variables";

body, html {
	background: @main-background;
}

a {
	transition: @btn-transition-duration color;
	text-decoration: underline;
}

label {
	font-size: @font-size-base-minus;
	font-weight: 500;
}

em {
	font-style: italic;
}

// hide spinners on <input type="number" />
input[type=number] {
	-moz-appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&::-ms-clear {
		display :none;
	}
}
