@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@checkbox-input-size: 20px;
@checkbox-padding-left: 30px;
@checkbox-additional-hitbox: 15px;

.container {
	display: flex;
	flex-wrap: wrap;

	font-size: @font-size-base;
	left: -@checkbox-additional-hitbox;
	margin-right: -15px;
	position: relative;
	font-weight: normal;
	cursor: pointer;
}

.invalid:not(.checked):before {
	border-color: @color-red-validation;
}

.input {
	position: absolute;
	left: -9000px;

	&:focus + .focus-ring {
		outline: 1px dotted black;
		outline: -webkit-focus-ring-color auto 5px;
		z-index: 4;

		&.suppressed {
			outline: none;
		}
	}
}

.tick {
	color: white;
	position: absolute;
	left: -5px;
	top: -3px;

	&.suppressed {
		opacity: 0;
	}
}
.focus-ring {
	display: block;
	margin-left: @checkbox-additional-hitbox;
	max-width: 100%;
}
.contents {
	display: block;
	flex-grow: 1;
	position: relative;
	padding-left: @checkbox-padding-left;
	text-align: left;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 2px;
		left: 0;
		width: @checkbox-input-size;
		height: @checkbox-input-size;
		border-radius: @border-radius-small;
		border: 1px solid @gray-light;
		background-color: white;
		transition-property: border-color, background-color;
		transition-duration: @transition-duration-short;
		transition-timing-function: ease-in-out;

		.checked & {
			background-color: @color-complementary-green;
			border-color: transparent;
		}

		&.invalid {
			border-color: @color-red-validation;
		}
	}
}
.validation-error {
	display: block;
	margin-left: @checkbox-padding-left + @checkbox-additional-hitbox;
}
