@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

:export {
	transitionDurationMs: @transition-duration-ms;
}

@panel-heading-padding: @grid-gutter-width*4;

.panel-heading {
	display: flex;
	flex-direction: column;

	:global .panel-subtitle {
		flex-grow: 1;
	}

	&-with-tabs {
		padding-bottom: 0;
		border-bottom: none;
	}
}

.panel-heading-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.tabs {
	margin-top: @grid-gutter-width / 2;

	@media @small {
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}
}

.apple-icon {
	color: white;
}

.apple-button {
	background-color: black;

	span {
		height: 16px;
		display: block;
	}

	@media not @small {
		@panel-button-padding-horizontal: 20px;
		width: auto;
		min-width: 260px;
		padding-left: @panel-button-padding-horizontal;
		padding-right: @panel-button-padding-horizontal;
		border: none;
	}
}

.terms {
	padding-bottom: 16px;
}

.apple-wrapper {
	display: block;
	padding-top: 16px;
}

.tabs-extended {
	button {
		min-height: 50px;
	}

	@media @small {
		button {
			line-height: normal;
			min-height: 55px;
		}
	}
}
