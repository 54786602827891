@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.tooltip {
	flex: none;
	margin-bottom: @spacing-base;
}

.label {
	flex: none;

	&-container {
		display: flex;
		align-items: center;
	}
}
