@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@color-heart-animation: #45bc78;

.heart-container {
	margin: 0 auto -@line-height-computed;
	display: block;
	width: 150px;
	height: 150px;
	position: relative;
}

.circle {
	background-color: @color-heart-animation;
	width: 96px;
	height: 96px;
	border-radius: 50%;
	position: absolute;
	top: ~'calc(50% - 48px)';
	left: ~'calc(50% - 48px)';
}

.heart {
	animation: heart-fall 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	color: white;
	position: absolute;
	left: ~'calc(50% - 27px)';
	top: ~'calc(50% - 21px)';
}

.burst-ray {
	background-color: @color-heart-animation;
	width: 3px;
	height: 40px;
	border-radius: 50%;
	position: absolute;
	left: ~'calc(50% - 1.5px)';
	top: ~'calc(50% - 20px)';
}

.loopingClass (@index) when (@index > 0) {
	@angle: 30deg * @index;
	@burst-size: 70px;
	.burst div:nth-child(@{index}) {
		animation: ~'burst-scale-@{index}' 0.8s ease-out;
		animation-delay: 0.7s;
		transform-origin: 50% 50%;
		opacity: 1;
		transform: rotate(@angle) translateY(0) scaleY(1);
	}

	@keyframes ~"burst-scale-@{index}" {
		0% {
			transform: rotate(@angle) translateY(0) scaleY(1);
			opacity: 0;
		}
		50% {
			transform: rotate(@angle) translateY(@burst-size) scaleY(0.2);
			opacity: 1;
		}

		100% {
			transform: rotate(@angle) translateY(@burst-size) scaleY(0);
			opacity: 0;
		}
	}

	.loopingClass(@index - 1);
}

.loopingClass(12);

@keyframes heart-fall {
	0% {
		transform: translateX(0) translateY(-120px) scale(1);
		opacity: 0;
	}
	100% {
		transform: translateX(0) translateY(0) scale(1);
		opacity: 1;
	}
}
