.dpe-unavailable {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		height: 96px;
		width: 96px;
	}

	.row {
		width: auto;
		margin-top: -100px;
	}

	.content {
		text-align: center;
	}
}
