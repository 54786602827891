@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	z-index: 1;
	position: fixed;
	height: 200%;
	width: 200%;
	top: -50%;
	left: -50%;
}

.image {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 50%;
	min-height: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.gradients {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	background-image: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.85) 0%,
		rgba(0, 0, 0, 0) 40%,
		rgba(0, 0, 0, 0) 60%,
		rgba(0, 0, 0, 0.85) 100%
	);

	@media @small {
		background-color: rgba(0, 0, 0, 0.5);
		background-image: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0) 60%,
			rgba(0, 0, 0, 0.5) 100%
		);
	}
}
