@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

:export {
	notificationTransitionDuration: @notification-transition-duration;
}

@notification-transition-duration: 300;
@dismiss-btn-width: 52px;

.notification {
	position: fixed;
	top: 0;
	left: 0;

	background-color: #0d8d43;
	color: white;

	width: 100%;
	min-height: @main-header-height;
	padding-left: @alert-padding-sm;
	padding-right: @alert-padding-sm;
	display: flex;
	z-index: @zindex-notification-overlay;

	font-size: @font-size-base-plus;
	line-height: 20px;

	@media @small {
		min-height: @main-header-height-small;
		padding-right: 0;
		font-size: @font-size-base;
	}

	&:hover {
		background-color: #076e32;
	}

	&-enter {
		opacity: 0.01;
		transform: translate3d(0, -100%, 0);
	}

	&-enter-active {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: transform ~'@{notification-transition-duration}ms', opacity ~'@{notification-transition-duration}ms';
		transition-timing-function: ease-in-out;
	}

	&-exit-active {
		opacity: 0.01;
		transform: translate3d(0, -100%, 0);
		transition: transform ~'@{notification-transition-duration}ms', opacity ~'@{notification-transition-duration}ms';
		transition-timing-function: ease-in-out;
	}

	&-icon {
		height: @icon-size-standard;
		width: @icon-size-standard;
		min-width: @icon-size-standard;
		border: 1px solid white;
		border-radius: 100%;
		align-self: center;

		@media @small {
			display: none;
		}
	}

	&-container {
		padding: 0;
		display: flex;
		align-items: stretch;
		margin-left: auto;
		margin-right: auto;

		@media @small {
			width: 100%;
		}
	}

	&-text {
		padding: @alert-padding-sm;
	}

	&-dismiss {
		border: none;
		background: transparent;
		color: white;
		padding-left: @alert-padding-sm;
		padding-right: @alert-padding-sm;
		display: block;
		min-height: @main-header-height-small;
		width: @dismiss-btn-width;

		&:focus {
			outline: none;
		}

		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	&-content {
		display: flex;
		flex-grow: 1;
		align-items: center;
		//Required to make IE render correctly
		min-height: @main-header-height-small;
		max-width: @screen-lg;

		@media not @small {
			padding-left: @dismiss-btn-width;
			min-height: @main-header-height;
			justify-content: center;
		}
	}

	&-error:hover,
	&-error {
		background-color: white;
		color: @color-red;
		&:hover {
			background-color: white;
		}
	}

	&-error-alert {
		color: @color-dark-red;
		border: none;
		border-radius: 0;
	}

	&-error-dismiss {
		color: @gray-lighter;
		&:hover {
			color: white;
		}
	}

	&-warning:hover,
	&-warning {
		background-color: #b7450e;
		color: white;
		&:hover {
			background-color: #b7450e;
		}
	}

	&-warning-alert {
		color: white;
		border: none;
		border-radius: 0;
	}

	&-warning-dismiss {
		color: @gray-lighter;
		&:hover {
			color: white;
		}
	}
}

.email-verification {
	&-text {
		margin-right: 5px;
	}

	&-action {
		margin-right: 5px;
		font-size: @font-size-base-plus;
	}
}
