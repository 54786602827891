.loading {
  width: 20px;
  height: 20px;
  color: #1173bc;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tick-container {
  width: 20px;
  height: 20px;
}
.tick {
  position: relative;
  height: 20px;
  width: 20px;
  color: #27a86c;
  transform: translateX(5px) rotateZ(-45deg);
}
.tick:before,
.tick:after {
  content: '';
  height: 4px;
  width: 4px;
  position: absolute;
  left: 0;
  background-color: currentColor;
  transform: translateY(-10px);
}
.tick:before {
  height: 0;
  top: 50%;
  animation: tickHorizontal 300ms ease-out forwards;
}
.tick:after {
  width: 0;
  bottom: 0;
  animation: tickVertical 300ms ease-out forwards;
}
@keyframes tickHorizontal {
  0% {
    height: 0;
  }
  100% {
    height: 40%;
  }
}
@keyframes tickVertical {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}
