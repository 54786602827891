@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@brand-icon-width: 48px;
@brand-icon-transition-duration: 150ms;

:export {
	brandIconTransitionDuration: @brand-icon-transition-duration;
}

.input-wrapper {
	position: relative;

	:global .form-control {
		padding-left: @input-padding-horizontal + @brand-icon-width;
	}
}

.brand-icon-wrapper {
	pointer-events: none;
	position: absolute;
	display: flex;
	height: 100%;
	width: @brand-icon-width;
	left: @input-padding-horizontal / 2;
	align-items: center;
	justify-content: center;
}

.brand-icon-enter {
	opacity: 0;
	transform: translateY(20%);
}

.brand-icon-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition-property: opacity, transform;
	transition-duration: @brand-icon-transition-duration;
}
