@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.form-control-frequency-combo-button {
	display: block;
	box-sizing: border-box;
	position: relative;
	align-items: center;

	@frequency-combo-button-border-width: 1px;
	@svg-height: 30px;
	@button-line-height: 1.2;

	flex: 1;
	display: flex;
	align-self: stretch;
	align-items: center;
	justify-content: center;

	&-label {
		line-height: @button-line-height;
		flex: 1;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		border: @frequency-combo-button-border-width solid @border-color;
		border-right: none;
		background-color: white;
		margin: 0;
		text-align: center;
		z-index: 1;
		width: 100%;
		cursor: pointer;
		transition: background-color @btn-transition-duration ease-in-out, color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		&.is-active {
			z-index: 3;
		}

		&:first-of-type {
			border-top-left-radius: @border-radius-base;
			border-bottom-left-radius: @border-radius-base;
		}

		&:not(:first-of-type) {
			margin-left: -@frequency-combo-button-border-width;
		}

		&:hover {
			z-index: 2;
		}
	}

	&-radio {
		position: absolute;
		left: -9000px;
		opacity: 0;

		&:focus {
			+ .form-control-frequency-combo-button-label {
				outline: 1px dotted black;
				outline: -webkit-focus-ring-color auto 5px;
				z-index: 4;
			}
		}
	}

	.svg-icon {
		@padding-top-bottom: -((@svg-height - (@font-size-base-minus * @button-line-height)) / 2);
		margin: @padding-top-bottom 10px @padding-top-bottom 0;

		@media @small {
			display: none;
		}
	}

	@media @small {
		display: none;
	}
}

.form-control-frequency-combo-dropdown {
	@frequency-combo-button-border-width: 1px;
	@button-line-height: 1.2;

	select {
		border: @frequency-combo-button-border-width solid @border-color;
		border-radius: 0;
		border-top-right-radius: @border-radius-base;
		border-bottom-right-radius: @border-radius-base;

		line-height: @button-line-height;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 13px 10px;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		text-align-last: center;
		z-index: 1;
		width: 100%;
		cursor: pointer;
		transition: background-color @btn-transition-duration ease-in-out, color 0.2s ease-in-out,
			@border-color 0.2s ease-in-out;

		@-moz-document url-prefix() {
			padding: 0 21px 0 0 !important;
		}

		&:focus,
		&:hover {
			+ span {
				color: white;
			}
		}

		&:hover {
			border-radius: 0;
			border-top-right-radius: @border-radius-base;
			border-bottom-right-radius: @border-radius-base;
		}

		&:focus {
			outline: 1px dotted black;
			outline: -webkit-focus-ring-color auto 5px;
		}

		+ span {
			z-index: 4;
		}

		&.is-active {
			z-index: 3;
			border-top-right-radius: @border-radius-base;
			border-bottom-right-radius: @border-radius-base;

			+ span {
				color: white;
			}
		}

		&.is-untoggled {
			&:focus {
				outline: none;
				outline: -webkit-focus-ring-color none;
				background-color: white;
				color: @text-color;
				border: @frequency-combo-button-border-width solid @border-color;

				+ span {
					color: @gray-mid;
				}
			}
		}

		option {
			background-color: white;
			color: @text-color;
			font-size: inherit;
			text-align: left;
		}
	}

	@media @small {
		display: none;
	}
}

.form-control-frequency-mobile-dropdown {
	select {
		option {
			&:first-child,
			&:first-of-type {
				display: none;
			}
		}
	}

	@media not @small {
		display: none;
	}

	@media @small {
		display: inherit;
	}
}
