@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.icon-link {
	color: currentColor;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	text-decoration: none;
	border-bottom-style: none;

	&:hover,
	&:focus,
	&:active {
		color: currentColor;
		text-decoration: none;
		.content {
			border-bottom: 1px solid;
		}
	}

	&.disabled,
	&:active.disabled,
	&:focus.disabled,
	&:hover.disabled,
	&[disabled],
	&:active[disabled],
	&:focus[disabled],
	&:hover[disabled] {
		color: @btn-link-disabled-color;
		cursor: not-allowed;
		.content {
			border-bottom: none;
		}
	}
}

.content {
	line-height: 1.4em;
	align-self: center;
	border-bottom: 1px solid transparent;

	// margin before or after  the content or both depending on preceded or following elements in DOM
	* + &,
	& + * {
		margin-left: 5px;
	}
}

.link {
	font-size: @font-size-base-minus;
	color: currentColor;
	text-decoration: none;
	border-bottom: 1px dashed;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		border-bottom: 1px solid;
		color: currentColor;
	}

	&.disabled,
	&:active.disabled,
	&:focus.disabled,
	&:hover.disabled,
	&[disabled],
	&:active[disabled],
	&:focus[disabled],
	&:hover[disabled] {
		cursor: not-allowed;
		color: @btn-link-disabled-color;
		border-bottom: 1px dashed;
	}

	&-inherit-font-size {
		font-size: inherit;
	}
}
