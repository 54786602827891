p {
	margin-bottom: @paragraph-spacing;
}

.lead {
	font-size: @font-size-base-plus;
}

small,
.small {
	font-size: @font-size-small;
}
