@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
.supported-label {
	margin-top: 30px;
	display: inline-block;
	font-size: @font-size-small;
	color: @gray-mid;
}

.col-zipcode {
	@media @small {
		clear: both;
	}
}

.supported-cards-wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}
