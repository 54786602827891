@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	display: flex;
	align-items: center;
	position: relative;
	padding: 7px @alert-padding-sm;
	margin-bottom: @spacing-vertical-small;
	border: 1px solid @color-red-validation;
	border-radius: @alert-border-radius;
	border-left-width: @alert-highlight-border-width;
	background-color: white;
	color: @color-red-validation;
	animation: fadein @transition-duration-long;
	line-height: @line-height-small;

	@media @small {
		flex-direction: column;
		border-left-width: 1px;
		border-top-width: @alert-highlight-border-width;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.content {
	flex: 1 1 auto;
	text-align: left;

	@media @small {
		text-align: center;
		max-width: 100%;
	}
}

.icon {
	flex: none;
	align-self: center;
	margin-right: @alert-padding-sm;

	&-multi-line {
		flex: none;
		align-self: flex-start;
		margin-right: @alert-padding-sm;
	}

	@media @small {
		margin-right: 0;
		margin-bottom: @spacing-base;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
