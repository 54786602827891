@import 'variables';

.form-control-split-button {
	display: flex;
	box-sizing: border-box;
	position: relative;
	align-items: center;

	@form-control-split-button-border-width: 1px;
	@svg-height: 30px;
	@button-line-height: 1.2;

	&-label {
		line-height: @button-line-height;
		flex: 2 1 auto;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		border: @form-control-split-button-border-width solid @border-color;
		background-color: white;
		margin: 0;
		padding: 13px 10px;
		text-align: center;
		z-index: 1;
		cursor: pointer;
		transition: background-color @btn-transition-duration ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

		&:not(:first-of-type) {
			margin-left: -@form-control-split-button-border-width;
		}

		&:first-of-type {
			border-top-left-radius: @border-radius-base;
			border-bottom-left-radius: @border-radius-base;
		}
		&:last-of-type {
			border-top-right-radius: @border-radius-base;
			border-bottom-right-radius: @border-radius-base;
		}

		&:hover {
			z-index: 2;
		}

		&.is-active {
			z-index: 3;
		}
	}

	&-radio {
		position: absolute;
		left: -9000px;
		opacity: 0;

		&:focus {
			+ .form-control-split-button-label {
				outline: 1px dotted black;
				outline: -webkit-focus-ring-color auto 5px;
				z-index: 4;
			}
		}
	}

	&-has-uniform-width {

		label {
			width: 100%;
		}
	}

	.svg-icon {
		@padding-top-bottom: -((@svg-height - (@font-size-base-minus * @button-line-height)) / 2);
		margin: @padding-top-bottom 10px @padding-top-bottom 0;

		@media @small {
			display: none;
		}
	}
}
