// Used to visually hide elements on page but still be available to screen readers
.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

body:hover .visually-hidden a,
body:hover .visually-hidden input,
body:hover .visually-hidden button {
    display: none !important;
}

// Use to hide an element from the user but not the browser,
// eg for Safari's credit card autofill that only works with
// separate month/year fields.
.user-hidden {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1000;
    pointer-events: none;
}
