@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.security-code-field {
	position: relative;
}

.need-help {
	position: absolute;
	top: 0;
	right: floor((@grid-gutter-width / 2));

	@media @small {
		position: static;
		margin-top: @spacing-base;
	}
}

.remember {
	display: flex;
	justify-content: center;
}

.info-icon-offset {
	margin-top: -4px;
}

.mobile-number {
	white-space: nowrap;
}

.resend-code {
	white-space: nowrap;
}

.fastmode-info {
	display: flex;
	border: 1px solid @brand-warning;
	background-color: lighten(@brand-warning, 34%);
	padding: @font-size-data-grid-header;

	&-text {
		font-size: 13px;
		margin: 0;
	}

	&-icon {
		width: 35px;
		margin: 2px @font-size-data-grid-header 0 0;
		color: @brand-warning;
	}
}
