.pika-button {
	.is-selected & {
		background-color: @gray-darker;
		color: white;
	}
	.is-today & {
		color: black;
	}
	.is-today.is-selected & {
		color: white;
	}

	&:hover {
		background-color: @gray-mid !important;
		color: white !important;
	}
}

.pika-table {
	abbr, abbr[title] {
		border: none;
		text-decoration: none;
	}
	th {
		padding-bottom: 0;
		color: @gray-mid;
	}
}

.pika-single {
	margin-top: 5px;
	margin-left: 12px;
}
