.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
.container {
  border-radius: 3px;
  backdrop-filter: blur(2px);
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 0 32px -8px rgba(9, 30, 66, 0.25);
  transition: background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.8);
}
.container:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}
.heading-text {
  line-height: 20px;
  margin-right: 16px;
}
.copy {
  width: 20px;
  height: 20px;
  background: 0;
  border: 0;
  padding: 0;
  color: #1173bc;
}
.copy:hover,
.copy:focus {
  box-shadow: 0 0 2px 1px #1173bc;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.features {
  padding: 0 10px 10px;
  overflow: auto;
  max-height: calc(100vh - 70px - 36px);
}
.feature {
  border: 1px solid #aeb1b5;
  border-radius: 3px;
  padding: 8px;
}
.feature + .feature {
  margin-top: 8px;
}
.feature-heading {
  margin: -8px -8px 8px;
  padding: 8px;
  border-bottom: 1px solid #aeb1b5;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
}
.override {
  display: flex;
  align-items: center;
}
.override-label {
  padding-left: 8px;
}
