.container {
	@media @small {
		.container-fixed(@grid-gutter-width*2);
	}
}

.col-centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}
