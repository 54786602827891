.payment-error {
	width: 100%;
}

.description {
	margin: 0;
}

.nowrap{
	white-space: nowrap;
}
