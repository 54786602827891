@icon-size: 20px;
@tick-animation-duration: 300ms;

.loading {
	width: @icon-size;
	height: @icon-size;
	color: #1173bc;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tick-container {
	width: @icon-size;
	height: @icon-size;
}

.tick {
	position: relative;
	height: @icon-size;
	width: @icon-size;
	color: #27a86c;
	transform: translateX(5px) rotateZ(-45deg);

	&:before,
	&:after {
		content: '';
		height: 4px;
		width: 4px;
		position: absolute;
		left: 0;
		background-color: currentColor;
		transform: translateY(-10px);
	}

	&:before {
		height: 0;
		top: 50%;
		animation: tickHorizontal @tick-animation-duration ease-out forwards;
	}

	&:after {
		width: 0;
		bottom: 0;
		animation: tickVertical @tick-animation-duration ease-out forwards;
	}
}

@keyframes tickHorizontal {
	0% {
		height: 0;
	}
	100% {
		height: 40%;
	}
}

@keyframes tickVertical {
	0% {
		width: 0;
	}
	100% {
		width: 80%;
	}
}
