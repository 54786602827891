@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@ach-image-transition-props: @transition-duration ease-in-out;

.image {
	flex: none;
	text-align: center;
	font-size: @font-size-small;
	position: relative;
	z-index: 1;
	transition: color @ach-image-transition-props;

	// These padding adjustments counteract the IE flexbox bug with `justify-content: space-around`
	padding: 0 13%;

	@media @small {
		padding: 0 @spacing-base;
	}

	&-container {
		position: relative;
		display: flex;
		padding: @spacing-vertical-small;
		background: white;
		border-radius: @border-radius-small @border-radius-small 0 0;
		align-items: center;
		justify-content: space-around;
		border: 1px solid @gray-lightest;
		margin-bottom: @spacing-vertical-small;

		&:after {
			content: '';
			position: absolute;
			width: ~'calc(100% + 2px)';
			height: 67%;
			bottom: -1px;
			left: -1px;
			background: linear-gradient(rgba(255, 255, 255, 0), @color-form);
		}

		[class^='error-message'] + & {
			margin-top: @spacing-vertical;
		}
	}

	&-description {
		margin-top: 3px;
		padding: @spacing-base;
		border-top: 2px solid transparent;
		transition: border-color @ach-image-transition-props;
	}

	&:not(.image-active) {
		color: @text-muted;
	}

	&-active {
		.image-description {
			border-top-color: @text-color;
		}
	}
}
