@import "../bootstrap-variables";
@import "variables";

@panel-heading-padding-vertical: 36px;
@panel-footer-padding-vertical: @spacing-vertical;
@panel-heading-min-height: 177px;
@panel-body-form-padding-bottom: @panel-body-padding - @form-control-group-padding-bottom;

.panel {
	position: relative;
	border: none;
	.box-shadow-small;
	margin-bottom: 0;
	transition:
		border-top @transition-duration-long ease-in-out,
		transform @transition-duration-long ease-in-out;

	.container > & {
		min-height: 100%;
	}

	&-responsive {
		@media @small {
			border-radius: 0;
		}
	}

	&-highlight {
		border-top-width: 6px;
		border-top-style: solid;

		.panel-heading {
			.border-top-radius(0);
		}

		@media @small {
			border-top-width: 0;
		}
	}

	&-white {
		background: white;
	}

	&-heading,
	&-body,
	&-footer {
		@media @small {
			padding-left: @grid-gutter-width;
			padding-right: @grid-gutter-width;
		}

		@media not @small{
			.button{
				@panel-button-padding-horizontal: 20px;
				width: auto;
				min-width: 260px;
				padding-left: @panel-button-padding-horizontal;
				padding-right: @panel-button-padding-horizontal;
			}
		}
	}

	&-heading {
		padding-top: @panel-heading-padding-vertical;
		padding-bottom: @panel-heading-padding-vertical - @paragraph-spacing;
		background-color: white;
		border-bottom: 1px solid @panel-inner-border;
		.border-top-radius(@panel-border-radius);
	}

	&-title {
		font-size: @font-size-base-plus;
		font-weight: 500;
		line-height: @headings-line-height;
		text-transform: uppercase;

		& + .lead {
			margin-top: @line-height-computed;
		}
	}

	&-subtitle {
		margin: @spacing-vertical-small 0;
		color: @text-muted;
	}

	&-body {
		padding: @panel-body-padding;
		border-bottom: 1px solid @panel-default-border;

		@media @small {
			padding-left: @panel-body-padding-mobile;
			padding-right: @panel-body-padding-mobile;
		}

		&-content {
			padding: @panel-body-padding;
			margin: -@panel-body-padding;
		}

		&-form {
			padding-bottom: @panel-body-form-padding-bottom;

			.panel-body-content {
				margin-bottom: -@panel-body-form-padding-bottom;
				padding-bottom: @panel-body-form-padding-bottom;
			}
		}
	}

	&-footer {
		border-top: none;
		padding-top: @panel-footer-padding-vertical;
		padding-bottom: @panel-footer-padding-vertical;
		text-align: center;
		.border-bottom-radius(@panel-border-radius);

		.btn-primary {

			@media @small {
				width: 100%;
			}
		}
	}
}
