@form-control-group-padding-bottom: @spacing-vertical-small;

.form-control {
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	background-color: white;
	padding: 0 @input-padding-horizontal;
	color: @input-color;
	height: @input-height-base;
	font-size: 17px;
	width: 100%;
	-moz-appearance: none;
	-webkit-appearance: none;

	&::-ms-expand {
		display: none;
	}

	&:focus {
		border-color: @input-border-focus;
		outline: none;
	}

	&::placeholder {
		color: @input-color-placeholder;
	}

	&-invalid {
		border-color: @color-red-validation;

		&:focus,
		&:active {
			border-color: @color-red-validation;
		}
	}

	select& {
		cursor: pointer;
		user-select: none // Remove the caret on `<select>`s in IE10+.
	}

	&-label {
		// label should be able to accomodate an icon
		min-height: @label-min-height;
		margin-bottom: @spacing-base;

		// flex makes it easier to align icons
		display: flex;
		align-items: center;

		.tooltip-icon {
			display: block;
			margin-left: @spacing-base;
			color: @gray-light;
		}
	}

	&-container {
		margin-bottom: @form-control-group-padding-bottom;
	}

	&-group {
		&-left {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right-width: 0;
		}

		&-right {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			&.form-control-invalid {
				border-left-color: @border-color;
			}
		}
	}
}

.validation-error-message {
	color: @color-red-validation;
	margin-top: 3px;
	font-size: @font-size-base-minus;
}

input[type=tel]::-ms-clear,
input[type=text]::-ms-clear {
	display: none;
}
