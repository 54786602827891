@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	color: @gray-dark;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
	font-weight: normal;
	text-align: center;
	font-size: @font-size-base;
	padding: 20px 0;

	@media @small {
		flex-wrap: wrap;
		font-size: @font-size-base-minus;
	}
}

.major {
	font-size: 100px;
	font-weight: 200;
	line-height: 0.8;

	@media @small {
		font-size: 70px;
	}
}

.medium,
.small {
	.fontSize(@amount-medium-font-size);
}
.large {
	.fontSize(@amount-large-font-size);
}

@media @small {
	.small {
		.fontSize(@amount-mobile-small-font-size);
	}
	.medium {
		.fontSize(@amount-mobile-medium-font-size);
	}
	.large {
		.fontSize(@amount-mobile-large-font-size);
	}
}

.fontSize(@fontSize) {
	font-size: @fontSize;
}

.currency-symbol {
	font-size: 24px;
	font-weight: 500;
	line-height: 1.1;
	margin-right: 5px;

	@media @small {
		font-size: 24px;
		font-weight: 400;
	}
}
