@baseColor: #e3e5e9;
@shimmerColor: #cacbd1;

.skeleton {
    display: inline-block;
    max-width: 100%;
	list-style: none;
	margin: 0;
    padding: 0;
    
    li {
        margin-top: 1em;
    }
    li:first-child {
        margin-top: 0;
    }
}

.skeleton-row {
    display: flex;
    flex: 1;
    max-width: 100%;
}

@keyframes shimmer-row {
	100% {
		width: 200%;
	}
}

.skeleton-row-content {
    height: 1em;
    min-width: 2em;
	position: relative;
	background-color: @baseColor;
	flex: 1 1 auto;
	overflow: hidden;
	border-radius: 1em;

	&:after {
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		width: 0px;
		bottom: 0px;
		height: 1.5em;
		overflow: hidden;
		background-image: linear-gradient(
			to left,
			@baseColor 25%,
			@shimmerColor 40%,
			@baseColor 80%
		);
		animation: shimmer-row 2s infinite;
	}
}