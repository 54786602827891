.table {
    display: flex;
    padding: var(--Scale-5x, 20px) var(--Scale-6x, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--Scale-3x, 12px);
    align-self: stretch;
    border-radius: var(--radius-small, 4px);
    background: var(--system-grey-100, #F5F6FA);
    width: 100%;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--Grey-Dark-200, #737478);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fund-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.amount {
    text-align: right;
    align-self: flex-start;
    margin-left: 20px;
}

.total {
    display: flex;
    padding-top: var(--Scale-3x, 12px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;   
    border-top: 1px solid #CACBD1;
    color: var(--Grey-Dark-200, #737478);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.total-amount {
    text-align: right;
}