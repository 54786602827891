@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@banner-fold-size: 8px;
@banner-font-size: 12px;
@banner-vertical-spacing: 5px;
@card-heading-font-size: 10px;
@card-container-bg: #f1f1f2;
@overlay-btn-size: 40px;
@play-btn-left-bottom-position: 10px;
@calendar-overlay-bg: #4d4d4d;
@responsive-border-color: #cccccc;
@audio-player-btn-size: 30px;
@audio-player-btn-left-bottom-position: 10px;

.container {
	display: flex;
	padding-top: 38px;
	overflow: hidden;
	margin: -30px -40px;
	text-align: left;

	@media @mobile {
		flex-direction: column;
		align-items: center;
	}
}

.phone-container {
	width: 248px;
	max-height: 485px;
	margin: 0 62px;
	position: relative;

	@media @mobile {
		flex-direction: column;
		align-items: center;
	}
}

.card-container {
	background-color: @card-container-bg;
	width: 218px;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	border-radius: 20px 20px 0 0;
}

.card {
	border-radius: @border-radius-base;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
	width: 200px;
	margin: 3px auto;
	background-color: white;
	flex: 1 1 auto;
}

.top-card,
.bottom-card {
	background-color: @color-form;
	flex: 0 0 auto;
	height: 70px;
}

.top-card {
	border-radius: 0 0 @border-radius-base @border-radius-base;
}

.bottom-card {
	border-radius: @border-radius-base @border-radius-base 0 0;
	margin-bottom: -15px;

	@media @mobile {
		margin-bottom: 0px;
		box-shadow: none;
		height: 30px;
	}
}

.image-wrap {
	position: relative;
}

.phone-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 248px;
	height: 100%;
	overflow: hidden;
}

.play-button-overlay {
	position: absolute;
	bottom: @play-btn-left-bottom-position;
	left: @play-btn-left-bottom-position;
	width: @overlay-btn-size;
	height: @overlay-btn-size;
}

.calendar-overlay {
	position: absolute;
	bottom: -9px;
	left: 15px;
	width: @overlay-btn-size;
	height: @overlay-btn-size;
	display: flex;
	flex-direction: column;
	background-color: @calendar-overlay-bg;
	align-items: center;
	justify-content: center;
	color: white;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;
	vertical-align: middle;
}

.calendar-overlay-large-text {
	font-size: 16px;
	white-space: nowrap;
	margin: 1px 0;
}

.calendar-overlay-medium-text {
	font-size: 11px;
	white-space: nowrap;
	margin: 1px 0;
}

.calendar-overlay-small-text {
	font-size: 9px;
	white-space: nowrap;
	margin: 2px 0;
}

.calendar-overlay-divider {
	overflow: hidden;
	height: 0;
	color: @calendar-overlay-bg;
	border-bottom: 1px solid #e6e6e6;
	margin: 3px 0;
}

.audio-player-overlay {
	position: absolute;
	bottom: @audio-player-btn-left-bottom-position;
	left: @audio-player-btn-left-bottom-position;
	width: @audio-player-btn-size;
	height: @audio-player-btn-size;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid white;
	border-radius: @audio-player-btn-size / 2;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
}

.card-text-content {
	padding: 16px 13px;
}

.card-title {
	font-size: @font-size-h4;
	line-height: @font-size-h4 + 2;
	margin: 0 0 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-subtitle {
	font-size: @font-size-base-plus / 2;
	line-height: @line-height-large;
	margin: 0 0 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-summary {
	line-height: 1.5;
	max-height: 4.5em;
	overflow: hidden;
	font-size: 10px;
}

.event-summary {
	line-height: 1.5;
	font-size: 10px;
	margin-top: 1em;
}

.card-tab-name {
	margin: 6px 0 0;
	font-size: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.banner {
	position: absolute;
	top: 39px;
	right: -13px;
	text-transform: uppercase;
	font-size: @banner-font-size;
	line-height: @banner-font-size + (2 * @banner-vertical-spacing);
	text-align: center;
	height: 20px;
	max-width: 215px;
	padding-right: 15px;
	padding-left: 15px;

	&::after {
		content: '';
		position: absolute;
		border-width: @banner-fold-size;
		border-style: solid;
		width: 0;
		height: 0;
		right: 0;
		bottom: 0 - @banner-fold-size * 2;
	}
}

.cta-wrapper {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;

	@media @mobile {
		align-items: center;
		border-top: 1px solid @responsive-border-color;
		padding: 50px 0 50px 0;
		height: 100%;
		width: 90%;
	}
}

.cta {
	font-size: @font-size-h3;
	font-weight: 300;
	text-align: center;
}

.cta-text {
	width: 242px;
	margin: 0 auto 25px;
}
