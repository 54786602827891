@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.back-to-app {
	margin-left: 28px;
}

.custom-success-thank-you-message {
	text-align: center;
}

.fastModeWrap {
	margin: @spacing-vertical 0;
}

.fastModeToggleWrap {
	flex-direction: row;
	display: flex;
	align-items: center;
}

.fastModelimit {
	font-size: @font-size-base-minus;
	text-align: left;
	max-width: 85%;
}

.fastModeTitle {
	margin: 0;
	font-size: @font-size-base-plus;
	font-weight: 700;
}

.fastModeSwitch {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}
