@import "../bootstrap-variables";
@import "../mixins/animations";
@import "../bootstrap/mixins/buttons";

.btn {
	font-size: @btn-font-size;
	padding: @btn-padding;
	outline: none !important;
	transition: @btn-transition-duration;
	height: @btn-static-height;
	line-height: @btn-static-height;
	font-weight: 600;
	min-width: @btn-min-width;

	&:hover,
	&:focus,
	&.focus {
		outline: none !important;
	}

	&[disabled] {
		opacity: 1;
	}
}

.btn-link,
.btn-dismiss,
.btn-tertiary,
.btn-sm,
.btn-xs {
	min-width: 0;
}

.btn-default[disabled],
.btn-primary[disabled] {
	color: @btn-disabled-color;
	background-color: @btn-default-disabled-bg;
	border-color: @btn-default-disabled-bg;

	&:hover {
		background-color: @btn-default-disabled-bg;
		border-color: @btn-default-disabled-bg;
	}
}

// Button icons
.btn .icon {
	display: inline-block;
	.square(@btn-icon-size);
	fill: currentColor;
	stroke: transparent;
	vertical-align: middle;
	margin-top: -(@btn-icon-size - @btn-font-size)/2;
	margin-right: 10px;

	&.icon-xs {
		.square(@btn-icon-size-xs);
		margin-top: -(@btn-icon-size-xs - @btn-font-size)/2;
	}

	&.icon-rotate {
		animation: rotate 0.5s infinite linear;
	}
}

@keyframes rotate {
	0% {
	  transform: rotate(0);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

// Alternate buttons
// --------------------------------------------------

.btn-cancel,
.btn-ghost {
	color: @btn-ghost-color;
	background-color: @btn-ghost-bg;
	border-color: @btn-ghost-border;

	&:focus,
	&.focus {
		color: @btn-ghost-active-color;
		background-color: darken(@btn-ghost-active-bg, 10%);
		border-color: darken(@btn-ghost-active-border, 25%);
	}

	&:hover {
		color: @btn-ghost-active-color;
		background-color: darken(@btn-ghost-active-bg, 10%);
		border-color: darken(@btn-ghost-active-border, 12%);
	}

	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @btn-ghost-active-color;
		background-color: darken(@btn-ghost-active-bg, 10%);
		border-color: darken(@btn-ghost-active-border, 12%);

		&:hover,
		&:focus,
		&.focus {
			color: @btn-ghost-active-color;
			background-color: darken(@btn-ghost-active-bg, 17%);
			border-color: darken(@btn-ghost-active-border, 25%);
		}
	}

	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		color: @btn-disabled-color;
		background-color: @btn-ghost-disabled-bg;
		border-color: @btn-ghost-disabled-border;

		&:hover,
		&:focus,
		&.focus {
			color: @btn-disabled-color;
			background-color: @btn-ghost-disabled-bg;
			border-color: @btn-ghost-disabled-border;
		}
	}
}

.btn-ghost-white {
  .button-variant(white; fadeout(white, 80%); white);
}

.btn-success {

	span{

		&.success-container{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			height: 42px;

			.svg {
			 	color: @color-green
			}

			.svg-ind-tick{
				margin-top: 13px;
				margin-right: 7px;
			}
		}
	}
}

// responsive button group
// if there is only 1 button in this group it will
// go full width
// --------------------------------------------------
.responsive-btn-group {
	display: flex;
	flex-direction: row;

	> .btn,
	> .dropdown {
		min-width: 0;
		padding: 0;
		flex-grow: 1;
		flex-basis: 0;

		margin-left: @grid-gutter-width / 2;
		margin-right: @grid-gutter-width / 2;

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}

	// fix some overrides
	.dropdown {
		> .btn {
			width: 100%;
			min-width: 0;
			padding: 0;
		}

		> .select-wrapper {
			width: 100%;
		}
	}
}

// responsive button group
// has a max width and centers items
.responsive-btn-group-centered {
	.responsive-btn-group;
	justify-content: center;
	.btn {
		max-width: 260px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.btn.mobile-full-width {
	@media @mobile {
		width: 100%;
		margin-bottom: 30px;
	}
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
	// line-height: ensure even-numbered height of button next to large input
	.button-size(@padding-base-vertical; @padding-large-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
}

// override sizes
.btn-sm {
		// line-height: ensure proper height of button next to small input
	.button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
	height: auto;

	&.btn-vertically-centered {
		margin-top: -@padding-small-vertical;
	}
}

.btn-xs {
	.button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
	height: auto;

	&.btn-vertically-centered {
		margin-top: -@padding-xs-vertical;
	}
}

.btn-link {
	font-weight: 500;
	border: 0;
	border-radius: 0;

	&:hover,
	&:focus,
	&.focus {
		color: @link-hover-color;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		color: @btn-disabled-color;

		&:hover,
		&:focus,
		&.focus {
			text-decoration: none;
			color: @btn-disabled-color;
		}
	}
}

.btn-link-inline {
	padding: 0;
	vertical-align: baseline;
}

.btn-gray-link {
	.btn-link;
	color: @gray;

	&:hover,
	&:focus,
	&.focus {
		color: darken(@gray, 15%);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		color: @gray;
	}
}

.btn-tertiary {
	.btn-link;
	font-weight: 500;
	color: @text-color;
	height: 24px;
	line-height: 24px;
	padding: 0;
	font-size: @font-size-base-minus;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		bottom: 0;
		right: 0;
		width: 100%;
		border-bottom: 1px dashed @gray-light;
	}

	&:hover,
	&:focus {
		color: @text-color;
		text-decoration: none;

		&:after {
			border-bottom-style: solid;
		}
	}

	&.btn-tertiary-with-icon {
		@btn-tertiary-icon-size: 14px;
		@btn-tertiary-icon-padding: 9px;
		.icon {
			.square(@btn-tertiary-icon-size);
			margin-bottom: -1px;
			margin-right: @btn-tertiary-icon-padding;
			fill: @gray;
		}
		&:after {
			width: ~"calc(100% - (@{btn-tertiary-icon-size} + @{btn-tertiary-icon-padding}))";
		}
	}
}

// Header buttons
.header-buttons {

	.btn-link {
		padding: 0;
		height: @line-height-computed;
	}

	@media @mobile {
		display: block;
		.btn {
			display: block;
			margin-top: @padding-large-vertical*2;
			text-align: left;
		}
	}

	@media @desktop {
		float: right;
		margin-right:-@padding-large-horizontal;

		.btn {
			margin: 0 @padding-large-horizontal;
		}
	}
}

.btn-dismiss {
	.btn-gray-link;
	padding: 0;
	height: auto;
	line-height: 16px;
	font-size: @font-size-base;
	font-weight: 300;

	.svg(23px, 23px);
	.svg {
		color: @gray;
		margin-bottom: -6px;
		padding-left: 6px;
	}
}

.btn-group {
	display: flex;

	> .btn {
		flex: 0 1 auto;
		float: none;
		background: @gray-lightest;
		border-color: @border-color;

		&:hover {
			color: @text-color;
		}

		&:focus,
		&.focus {
			color: @btn-default-color;
		}

		&:focus,
		&.focus,
		&:hover {
			background: @gray-light;
			border-color: @gray-light;
		}

		&.btn-selected {
			background: @gray-mid;
			border-color: @gray-mid;
			color: @btn-default-color;
		}
	}
}

.btn-unbranded {
	.button-variant(@btn-unbranded-color; @btn-unbranded-bg; @btn-unbranded-border);
}
