@import '../../../../Content/WebGiving/bootstrap-variables.less';

@slider-toggle-track-offset: 3px;
@slider-toggle-track-width: 50px;
@slider-toggle-track-height: 30px;

.toggle {
	display: inline-block;
	position: relative;
	width: @slider-toggle-track-width;
	height: @slider-toggle-track-height;
	border-radius: @slider-toggle-track-height;
	background-color: transparent;
	visibility: visible;
	vertical-align: middle;
	transition: all 0.3s ease;
	user-select: none;
	cursor: pointer;
	border: 1px solid @gray;
	color: @gray;

	.toggleBox {
		position: absolute;
		left: -1px;
		top: -1px;
		display: block;
		width: @slider-toggle-track-height;
		height: @slider-toggle-track-height;
		border-radius: 100%;
		background-color: transparent;
		visibility: visible;
		border: 1px solid @gray;
		transition: all 0.25s ease;
		visibility: visible;
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.active {
		border-color: @brand-success;
		color: @brand-success;
		background-color: @brand-success;
		.toggleBox {
			background: @btn-default-color;
			border-color: @brand-success;
			left: @slider-toggle-track-height - (@slider-toggle-track-offset * 3) - 2;
		}
	}

	.activeIcon,
	.inActiveIcon {
		position: absolute;
		top: -2px;
		left: 0;
	}

	.inActiveIcon {
		top: 7px;
		left: 6px;
	}
}

.checkbox {
	opacity: 0;
	position: absolute;
}
