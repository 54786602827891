@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.heading {
	color: @text-muted;
	font-size: @font-size-large;
	font-weight: 400;
}

.payer-covers-fees-checkbox label {
	margin-top: 16px;
	justify-content: center;
}

.anniversary-reminder {
	margin: 25px 0 12px;
	label {
		justify-content: center;
	}
}

.edit-link {
	font-size: @font-size-h6;
	color: @gray-dark;
	margin-top: 17px;

	@media @small {
		font-size: @font-size-small;
	}
}

.edit-icon {
	margin-right: 10px;
	vertical-align: middle;
}

.small-print {
	color: @text-muted;
	font-size: @font-size-small;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 0;
}

.payment-method-heading {
	margin-top: 0;
}

.apple-pay-icon {
	color:white;
}

.apple-pay-button {
	background-color:black;

	span {
		height: 16px;
		display: block;
	}
	@media not @small {
		width: auto;
		min-width: 260px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.separator {
	width: 80%;
	position: relative;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
	justify-content: center;

	&-text {
		font-size: 15px;
		line-height: 15px;
		display: block;
		color: @text-muted;

		&::before, &::after {
			content: "";
			position: absolute;
			background-color: @gray-lightest;
			display: block;
			width: 40%;
			height: 1px;
			left: 0;
			top: 50%;
		}

		&::after {
			left: auto;
			right: 0;
		}
	}

	@media not @small {
		width: auto;
		min-width: 260px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.give-button-container {
	display:flex;
	flex-direction: column;
	align-items: center;
}