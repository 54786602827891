@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.table {
    display: flex;
    padding: var(--Scale-5x, 8px) var(--Scale-6x, 8px);
    flex-direction: column;
    gap: var(--Scale-3x, 4px);
    align-self: stretch;
    border-radius: var(--radius-small, 4px);
    background: var(--system-grey-100, #F5F6FA);
    width: 100%;
}

.tiled-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--Grey-Dark-200, #737478);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #FFFFFF;
    height: 48px;
    border-radius: 4px;
}

.fund-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding-left: 8px;
    text-align: left;
}

.amount {
    text-align: right;
    margin-right: 10px;
}

.total {
    display: flex;
    padding-top: var(--Scale-3x, 12px);
    padding-bottom: var(--Scale-3x, 8px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--Grey-Dark-200, #737478);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.total-text {
    margin-left: 10px;
}

.total-amount {
    text-align: right;
    margin-right: 10px;
}

.error-icon {
    color: @color-dark-red;
    height: @icon-size;
    width: @icon-size;
    min-width: @icon-size;
    border: 1px solid white;
    align-self: center;
    margin-left: 10px;
}

.success-icon {
    color: @color-green;
    height: @icon-size;
    width: @icon-size;
    min-width: @icon-size;
    border: 1px solid white;
    align-self: center;
    border-radius: 100%;
    margin-left: 10px;
}

.partial-status-text {
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 20px;
}

.error-alert-container {
    margin-top: 12px;
}