@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.section {
	border-top: 2px solid @gray-lightest;
	display: flex;
	flex-direction: row;
	padding: @font-size-base-minus 0;
}

.text {
	margin: 0;
	flex: 1 1 auto;
}

.header {
	font-size: @font-size-base-plus;
	font-weight: normal;
	margin: 0 0 6px 0;
	line-height: 20px;
}

.link {
	margin: 0;
	text-align: right;
	flex: 0 0 200px;
	white-space: nowrap;
}

.link-item {
	margin-bottom: 5px;
}

.link-icon {
	vertical-align: middle;
}

.details {
	color: @gray-mid;
}

@media @mobile {
	.section {
		display: block;
	}

	.link {
		text-align: left;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: auto;
	}

	.link-item {
		flex: 0 0 auto;
		margin-right: 3em;
	}
}
