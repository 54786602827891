@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

:export {
	modalTransitionDuration: @transition-duration-long-ms;
}

@modal-horizontal-margin: auto;
@modal-top-margin: 10vh;
@modal-bottom-margin: 5vh;
@modal-max-width: 678px;
@modal-transition: ~'opacity @{transition-duration-long} ease-in-out';
@modal-content-padding: 40px;
@modal-content-padding-small: 20px;
@modal-content-padding-close: 64px;
@modal-focus-ring-extra-spacing: 7px;

.header {
	font-size: @font-size-h1;
	flex: none;
	width: 100%;
	padding: @modal-content-padding-small @modal-content-padding-close @modal-content-padding-small
		@modal-content-padding;

	@media @small {
		padding-left: @modal-content-padding-small;
		font-size: @font-size-h3;
	}
}

.content {
	flex: auto;
	overflow-y: auto;
	padding: 0 @modal-content-padding @modal-content-padding-small;

	@media @small {
		padding: 0 @modal-content-padding-small;
	}

	&-ios {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	p:first-child,
	h3:first-child {
		margin-top: 0;
	}
}

.container {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: @zindex-modal;

	&-enter {
		opacity: 0.01;
	}

	&-enter-active {
		opacity: 1;
		transition: @modal-transition;
	}

	&-exit-active {
		opacity: 0.01;
		transition: @modal-transition;
	}
}

.footer {
	flex: none;
	text-align: center;
	width: 100%;
	position: relative;
	padding: @panel-body-padding;

	@media @small {
		padding-left: @modal-content-padding-small;
		font-size: @font-size-h3;
	}
}

.background {
	background-color: black;
	opacity: 0.7;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	overflow-y: auto; //fix for IE10 not scrolling content

	@media @small {
		height: 100%;
	}

	@media not @small {
		margin: 10vh auto 5vh auto;
		border-radius: 4px;
		max-height: 100vh - @modal-top-margin - @modal-bottom-margin;
		max-width: @modal-max-width;
	}
}

.close {
	position: absolute;
	top: @modal-close-button-offset;
	right: @modal-close-button-offset;
	cursor: pointer;

	@media @small {
		top: 0;
		height: @modal-close-button-size + @modal-content-padding-small * 2;
	}

	&:focus {
		margin-top: -(@modal-focus-ring-extra-spacing);
		padding-top: @modal-focus-ring-extra-spacing;
	}
}

.close-icon {
	color: @gray;
}
