@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/webgiving_theme/utilities.less';
@import '../../../../Content/WebGiving/mixins/box-shadow.less';

@page-give-top-offset: -120px;

@amount-decorator-dollar-size: 24px;

@transition-target: 250ms;

@extended-banner-top-small: @main-banner-height-small + @spacing-vertical + 40 / 2;

@panel-header-min-height: 45px;
@panel-padding: @panel-body-padding - 2;

:export {
	pageGiveTopOffset: @page-give-top-offset;
}

.panel,
.footer {
	transform: translateY(@page-give-top-offset);

	@media @small {
		transform: translateY(0);
	}
}

.header {
	position: absolute;
	top: -(@main-banner-height + @page-give-top-offset + @spacing-vertical);
	left: 0;
	width: 100%;
	color: white;
	text-align: center;
	padding: 44px 0 0 0;
	transition: @transition-target;

	@media @small {
		top: -(@main-banner-height-small + @spacing-vertical / 2);
		padding-left: @panel-body-padding;
		padding-right: @panel-body-padding;
	}

	&--has-enhanced-trading-name {
		padding-top: 29px;

		@media @small {
			padding-top: 18px;
		}
	}

	&-lifted {
		@media @small {
			top: -@extended-banner-top-small;
			.amount-validation-error {
				margin-top: 10px;
			}
	
			.amount-container {
				margin-bottom: 0;
			}
		}
	}
}

.enhanced-trading-name {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	margin: 0 0 10px 0;

	@media @small {
		font-size: 14px;
		margin-bottom: 2px;
	}
}

.heading {
	margin-bottom: 22px;
	margin-top: 0;

	@media @small {
		margin-bottom: 12px;
	}
}

.giving-title {
	font-size: 32px;
	line-height: 1.3125;
	letter-spacing: 0.5px;

	@media @small {
		font-size: 20px;
		line-height: 1.5;
		margin-bottom: 0px;
	}
}

.intro {
	margin-bottom: @spacing-vertical;
}

.amount {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	transition: @transition-target;
	height: @amount-large-font-size;
	@media @small {
		margin: 0 -10px;
		height: @amount-mobile-large-font-size + 16px;
		&.medium {
			height: @amount-mobile-large-font-size;
		}
	}
}

.your-total {
	font-size: @font-size-base-minus;
	font-weight: 500;
}

.amount-validation-error {
	position: relative;
	margin-top: 20px;
	font-size: @font-size-small;
	font-weight: 400;
	line-height: 1.5;
	color: @color-red-validation;
	padding: 6px 10px;
	background-color: white;
	border-radius: @border-radius-small;
	.box-shadow-small;
	display: inline-flex;
	text-align: left;
	align-items: center;
	z-index: @zindex-popover;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		transform: rotate(45deg);
		top: -6px;
		background-color: white;
		left: ~'calc(50% - 8px)';
	}

	// This allows IE10-xs to try to word wrap, it's not perfect but at least it does it.
	// Not that people should be using an IE10-xs brower
	.error-content {
		width: 100%;
	}
}

.error-icon {
	margin-right: 10px;
}

.input {
	font-weight: 200;
	border: 0;
	line-height: 1;
	width: 100%;
	text-align: center;
	z-index: 1;
	position: relative;
	background: transparent;
	padding: 0;
	&:focus {
		outline: none;
	}
}

.decorator {
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	justify-content: center;
	display: flex;
	line-height: 1;
	user-select: none;
}

.wrapper {
	border-bottom: 1px solid white;
	position: relative;
	z-index: 0;
	line-height: 1;
	&.zero {
		border-bottom: 1px solid @white-50;
	}

	transition: border-bottom 0.5s ease;
}

.wrapperWithoutBorder {
	position: relative;
	z-index: 0;
	line-height: 1;
	border-bottom: none;
}

.dollar {
	font-size: @amount-decorator-dollar-size;
	vertical-align: super;
	position: absolute;
	left: -@amount-decorator-dollar-size;
	text-align: center;
	display: inline-block;
	width: @amount-decorator-dollar-size;
}
.dollar.web-giving-currency {
	left: -@amount-decorator-dollar-size * 4;
	width: @amount-decorator-dollar-size * 4;
}

.underline {
	color: transparent;
	user-select: none;
	&::selection {
		color: rgba(0, 0, 0, 0);
	}
}

.recurring-end {
	display: flex;

	.recurring-end-type,
	.recurring-end-details {
		flex: 1 0 0;
	}

	@media @small {
		display: block;
	}
}

.recurring-end-type {
	select {
		position: relative;
		border-radius: @input-border-radius 0 0 @input-border-radius;
		z-index: auto;

		&:focus {
			z-index: 1;
		}

		@media @small {
			border-radius: @input-border-radius;
		}
	}
}

.recurring-end-details {
	margin-left: -1px;
	padding-top: @icon-size-standard + @spacing-base; // replace space lost by hiding label

	@media @small {
		padding-top: 0;
	}

	> label {
		.visually-hidden;

		@media @small {
			// undo visually hidden
			position: static !important;
			clip: auto;
			height: auto !important;
			width: auto !important;
			overflow: visible;
		}
	}

	input {
		border-radius: 0 @input-border-radius @input-border-radius 0;
		position: relative;
		z-index: auto;

		&:focus {
			z-index: 1;
		}

		@media @small {
			border-radius: @input-border-radius;
			margin-left: 0;
		}
	}
}

.no-right-padding-on-desktop {
	@media not @small {
		padding-right: 0;
	}
}

.panel-has-header {
	padding-top: 0;
}

.panel-header {
	min-height: @panel-header-min-height;
	padding: 0 @panel-padding;
	margin: 0 -@panel-padding @panel-padding -@panel-padding;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: lighten(@gray-base, 95%);

	&-title,
	&-link {
		margin: 0;
		font-size: 16px;
		line-height: @panel-header-min-height;
	}

	&-title {
		font-weight: 500;
	}

	&-link {
		z-index: 5;
		text-align: right;
		background-color: transparent;
		outline: none;
		border: none;
	}
}


.logo {
	max-width: 208px;
	margin: 0 auto;
}

.header-content {
	display: block;
	text-align: center;
	padding: 22px 0;
}

.content {
	font-size: 16px;
}

.btn {
	width: auto;
	min-width: 300px;
	padding: 10px 25px;
	border: none;
	font-size: 16px;

	&:hover,
	&:focus,
	&:active {
		border: none;
	}

	@media @small {
		width: 100%;
	}
}

.givingOption {
	margin-top: 20px;
}

