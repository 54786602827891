@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.container {
	position: relative;
	display: flex;
	align-items: center;
}

.placement-left {
	:global .form-control {
		padding-left: @icon-size-standard + @input-padding-horizontal;
	}
}

.placement-right {
	:global .form-control {
		padding-right: @icon-size-standard + @input-padding-horizontal;
	}
}

.placement-left.squished {
	:global .form-control {
		padding-left: @icon-size-standard + floor(@input-padding-horizontal / 2);
	}
}

.placement-right.squished {
	:global .form-control {
		padding-right: @icon-size-standard + floor(@input-padding-horizontal / 2);
	}
}

.icon {
	position: absolute;
	height: @icon-size-standard;
	width: @icon-size-standard;
	pointer-events: none;
	top: ~'calc(50% - @{icon-size-standard} / 2)';
	color: @gray-light;
	z-index: 1;

	&.darker {
		color: @gray-mid;
	}

	&.small {
		top: ~'calc(50% - @{icon-size-small} / 2  - 1px)';
	}

	.placement-left > &,
	.placement-left > label > & {
		left: @input-padding-horizontal / 2;

		&.small {
			left: calc(@icon-size-small - 2px);
		}
	}

	.placement-right > &,
	.placement-right > label > & {
		right: @input-padding-horizontal / 2;

		&.small {
			right: 0;
		}
	}
}
