@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.logo {
	max-width: 208px;
	margin: 0 auto;
}

.header-content {
	display: block;
	text-align: center;
	padding: 22px 0;
}

.hr {
	top: -32px;
	position: relative;
	margin: 0;

	@media @small {
		top: 0;
		position: absolute;
		margin: 0;
		width: 100%;
		left: 0;
	}
}

.btn {
	width: auto;
	padding: 10px 25px;
	border: none;

	&:hover,
	&:focus,
	&:active {
		border: none;
	}

	@media @small {
		width: 100%;
	}
}
