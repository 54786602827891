@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/mixins/overlay.less';

@header-text-color: @navbar-default-color;
@overlay-transition-props: @transition-duration ease-in-out;

.footer {
	font-size: 13px;
	margin-top: auto;
	padding-top: @spacing-vertical-large;
	transform: translateY(0);
	transition: transform @transition-duration-long ease-in-out;
	position: relative;
	text-align: center;
	z-index: @zindex-hero-image + 1;

	p {
		margin: 0 0 @padding-large-horizontal 0;
	}

	&-container {
		max-width: @main-container-max-width;
	}

	@media screen and (min-width: 1080px) {
		&-container {
			max-width: @main-container-max-width + @main-banner-height;
		}
	}

	&-links {
		padding-bottom: 12px;
	}

	&-link {
		color: @text-color;
		font-weight: 500;
	}

	&-bottom {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.wrapper {
	width: 100%;
	padding-bottom: @spacing-vertical;
	padding-top: @spacing-vertical;
	margin-top: auto;
}

.footer.footerDark {
	color: @white-70;
	
	.wrapper {
		background-color: rgba(0, 0, 0, 0.6);
	}

	a {
		color: @white-70;
		&:hover,
		&:focus,
		&:active {
			color: @white-50;
		}
	}

	.separator,
	p,
	.pushpay-logo {
		color: @white-70;
	}

	.brands {
		padding-top: 0;
		color: @white-70;

		&-wrapper {
			color: white;
		}
	}
}

.brands {
	padding-bottom: @spacing-vertical;
	padding-top: @spacing-vertical;
	text-align: center;

	&-wrapper {
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	&-title {
		display: block;
		font-size: 13px;
		padding-bottom: 8px;
	}
}

.info {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: none;

	@media @small {
		flex-direction: column;
	}
}

.pushpay-logo {
	color: @text-color;
	height: 16;
    width: 71px;
    margin-right: @padding-large-horizontal;

	&-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		@media @small {
			padding-bottom: @padding-large-horizontal;
		}
	}
}

.org-info {
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media @small {
		flex-direction: column;
	}
}

.separator {
	visibility: visible;
	margin: 0 10px;
	color: @text-color;
}
