@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.icon {
	border-radius: @border-radius-small;
	border: 1px solid @border-color;
	box-sizing: content-box;
	background: white;

	&:not(:first-child) {
		margin-left: @padding-xs-horizontal;
	}
}

.brand-icon {
	box-sizing: content-box;
	transform-origin: center;
}
