@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

:export {
	transitionDurationLongMs: @transition-duration-long-ms;
}

// Please Note: this styling tighly coupled with donor pledge entry

@extended-banner-height-small: @main-banner-height-small + 30;

.banner {
	opacity: 1;
	height: @main-banner-height;

	@media @small {
		height: @main-banner-height-small;
	}

	&-enter {
		opacity: 0;
		height: 0;
	}

	&-enter-active {
		.transition();
		opacity: 1;
		height: @main-banner-height;

		@media @small {
			height: @main-banner-height-small;
		}
	}

	&-exit-active {
		.transition();
		opacity: 0;
		height: 0;
	}

	&.extend-banner-mobile{
		@media @small {
			height: @extended-banner-height-small;
		}

		&-enter-active {
			@media @small {
				height: @extended-banner-height-small;
			}
		}
	}
}

@banner-offset: 90;
@extended-banner-ext-height-small: @main-banner-height-small + @banner-offset;

.banner-ext {
	opacity: 1;
	height: @main-banner-height;

	@media @small {
		height: @main-banner-height-small + @banner-offset;
	}

	&-enter {
		opacity: 0;
		height: 0;
	}

	&-enter-active {
		.transition();
		opacity: 1;
		height: @main-banner-height;

		@media @small {
			height: @main-banner-height-small + @banner-offset;
		}
	}

	&-exit-active {
		.transition();
		opacity: 0;
		height: 0;
	}
}

.transition() {
	transition-timing-function: ease-in-out;
	transition-property: height, opacity;
	transition-duration: @transition-duration-long;
}
