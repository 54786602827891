@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@tooltip-arrow-size: 10px;
@tooltip-transition-props: opacity, visibility;
@tooltip-transition-easing: 'ease-in-out';
@tooltip-transition: opacity @transition-duration ease-in-out;

:export {
	tooltipArrowSize: @tooltip-arrow-size;
	transitionDuration: @transition-duration-ms;
}

.button {
	display: inline-block;
	background: transparent;
	border: none;
	padding: 0;

	> svg,
	> span {
		pointer-events: none;
	}
}

.standard-button {
	margin-left: @spacing-base;
}

.modal-header {
	display: none;
	font-size: 130%;
	margin: 0;
	margin-bottom: 0.5em;
}

.tooltip {
	&-enter {
		opacity: 0.01;
	}

	&-enter-active {
		opacity: 1;
		transition: @tooltip-transition;
	}

	&-exit-active {
		opacity: 0.01;
		transition: @tooltip-transition;
	}
}

.inner {
	position: absolute;
	display: block;
	z-index: @zindex-tooltip;
	max-width: @tooltip-max-width;
	color: @tooltip-color;

	&:before {
		content: ' ';
		position: absolute;
		background-color: @tooltip-bg;
		display: block;
		width: @tooltip-arrow-size;
		height: @tooltip-arrow-size;
	}

	&.top:before {
		left: 50%;
		bottom: 0;
		transform: translateX(-50%) translateY(50%) rotateZ(45deg);
	}

	&.right:before {
		top: 50%;
		transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
	}

	&.left:before {
		right: 0;
		top: 50%;
		transform: translateX(50%) translateY(-50%) rotateZ(45deg);
	}

	&.bottom:before {
		left: 50%;
		transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
	}
}

.content {
	padding: 15px;
	border-radius: @border-radius-base;
	background-color: @tooltip-bg;
	box-shadow: 0 0 2px @tooltip-bg;
	font-size: 14px;
}

.close {
	display: none;
}

.background {
	display: none;
}

.tooltip-icon {
	display: block;
	color: @gray-light;
}

@media @small {
	.background {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0.6;
	}

	.inner {
		left: 0;
		right: unset;
		bottom: 0;
		position: fixed;
		max-width: none;
		width: 100%;
		color: black;

		&:before {
			display: none;
		}
	}

	.modal-header {
		display: block;
	}

	.content {
		text-align: left;
		border-radius: 0px;
		background-color: white;
		padding: 1em;
	}

	.close {
		display: block;
		float: right;
		border: none;
		background: transparent;
	}

	.icon {
		width: 1em;
		height: 1em;
	}
}
